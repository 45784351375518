import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { graphql } from 'gatsby';
import ClientCarouselCard from '../../molecules/ClientCarouselCard/ClientCarouselCard';
import Layout from '../../organisms/Layout/Layout';
import SlantBanner from '../../organisms/SlantBanner/SlantBanner';
import useLocalize from '../../../hooks/useLocalize';

// renamed to match Sanity schema
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import { renderPageSections } from '../renderPageSections';
import sanityImage from '../../../utils/sanityImage';
import {
  breakpoints,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';

const AllClientsPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanityAllclientspage === null) {
    return null;
  }
  const data = useLocalize(rawData, locale);

  const allClientsPageProps = {
    id: data.sanityAllclientspage._id,
    allClientsSlantBanner: data.sanityAllclientspage._rawAllClientsSlantBanner,
    backgroundImage: data.sanityAllclientspage._rawBackgroundImage,
    metadata: { ...data.sanityAllclientspage._rawRoute, href },
    sections: data.sanityAllclientspage._rawSections,
    bubbleCtaSection: data.sanityAllclientspage._rawBubbleCtaSection,
  };

  const backgroundAndSlantBannerCss = css`
    overflow: hidden;
    background-repeat: repeat-y;
    background-size: ${pageDimensions.largeDesktopWidth};

    .slant-banner {
      position: relative;
      z-index: 2;

      section:last-of-type {
        margin-top: ${uc('-250px')};

        @media (${breakpoints.tablet}) {
          margin-top: ${uc('30px')};
        }
      }
    }
  `;

  const bgImage = {
    backgroundImage: `url(${sanityImage(allClientsPageProps.backgroundImage)
      .auto('format')
      .width(1400)
      .fit('max')
      .url()})`,
  };

  const allClientsSlantBannerCss = css`
    @media (${breakpoints.smallDesktopMax}) {
      padding-bottom: ${uc('160px')};
    }
  `;

  return (
    <Layout
      menuColor="white"
      locale={locale}
      sanityId={allClientsPageProps.id}
      {...allClientsPageProps.metadata}
    >
      <div css={backgroundAndSlantBannerCss} style={bgImage}>
        <SlantBanner
          templateSpecificStyles={allClientsSlantBannerCss}
          {...allClientsPageProps.allClientsSlantBanner}
        >
          <ClientCarouselCard
            {...allClientsPageProps.allClientsSlantBanner.clientCarouselCard}
          />
        </SlantBanner>
        {renderPageSections(allClientsPageProps.sections)}
        {allClientsPageProps.bubbleCtaSection && (
          <BubbleCtaSection {...allClientsPageProps.bubbleCtaSection} />
        )}
      </div>
    </Layout>
  );
};

AllClientsPage.propTypes = {
  data: PropTypes.shape({ sanityAllclientspage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }),
};

AllClientsPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: {
    locale: 'us',
  },
};

export default AllClientsPage;

export const query = graphql`
  query($slug: String) {
    sanityAllclientspage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawBackgroundImage(resolveReferences: { maxDepth: 100 })
      _rawAllClientsSlantBanner(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
      _id
    }
  }
`;
